
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ShippingRecord } from "@/store/modules/ShippingRecordModule";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "shipping-records-listing",
  components: {},
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const searchQuery = ref({
      user_mobile: "",
      shipping_no: ""
    });

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0
    });

    const tableData = ref<ShippingRecord[]>([]);
    const loading = computed(() => store.state.ShippingRecordModule.loading);

    const editingId = ref<number | null>(null);
    const editForm = ref({
      shipping_provider: "",
      shipping_no: ""
    });

    const loadData = () => {
      const params: any = {
        currentPage: page.value.currentPage,
        pageSize: page.value.pageSize,
        user_mobile: searchQuery.value.user_mobile,
        shipping_no: searchQuery.value.shipping_no
      };

      store.dispatch(Actions.FETCH_SHIPPING_RECORDS, params).then(() => {
        tableData.value = store.getters.currentShippingRecords;
        page.value.totalResult = store.getters.currentShippingRecordCount;
      });
    };

    const handleSearch = () => {
      page.value.currentPage = 1;
      loadData();
    };

    const onPageChange = (evt: any) => {
      const { type, currentPage, pageSize } = evt;
      
      if (type === 'size') {
        page.value.currentPage = 1;
      } else {
        page.value.currentPage = currentPage;
      }
      
      page.value.pageSize = pageSize;
      loadData();
    };

    const formatDate = (date: string) => {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    };

    const startEdit = (record: ShippingRecord) => {
      editingId.value = record.id;
      editForm.value = {
        shipping_provider: record.shipping_provider,
        shipping_no: record.shipping_no
      };
    };

    const cancelEdit = () => {
      editingId.value = null;
      editForm.value = {
        shipping_provider: "",
        shipping_no: ""
      };
    };

    const handleSave = async (record: ShippingRecord) => {
      if (!editForm.value.shipping_provider.trim()) {
        Swal.fire({
          text: t("shipping_provider_required"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("confirm"),
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        });
        return;
      }
      
      if (!editForm.value.shipping_no.trim()) {
        Swal.fire({
          text: t("shipping_no_required"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("confirm"),
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        });
        return;
      }

      try {
        await store.dispatch(Actions.UPDATE_SHIPPING_RECORD, {
          id: record.id,
          shipping_provider: editForm.value.shipping_provider.trim(),
          shipping_no: editForm.value.shipping_no.trim()
        });
        
        Swal.fire({
          text: t("update_success"),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t("confirm"),
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        });
        
        loadData();
        cancelEdit();
      } catch (error) {
        Swal.fire({
          text: t("update_fail"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("confirm"),
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        });
      }
    };

    const onDownload = async () => {
      try {
        const params = {
          user_mobile: searchQuery.value.user_mobile,
          shipping_no: searchQuery.value.shipping_no
        };

        const response = await store.dispatch(Actions.DOWNLOAD_SHIPPING_RECORDS, params);
        
        // 创建临时链接下载文件
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'shipping_records.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

      } catch (error) {
        Swal.fire({
          text: t('download_fail'),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t('confirm'),
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("发货记录", ["发货管理"]);
      loadData();
    });

    return {
      tableData,
      loading,
      searchQuery,
      page,
      handleSearch,
      onPageChange,
      formatDate,
      editingId,
      editForm,
      startEdit,
      cancelEdit,
      handleSave,
      onDownload,
    };
  },
});
